import React from 'react';
import { SEO } from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Layout } from '../layouts/Layout';
import classNames from 'classnames';

export const LocationMapImage = () => {
  return (
    <StaticImage
      src="../resources/location-map.png"
      className="w-full h-auto"
      objectFit="cover"
      objectPosition="center"
      placeholder="none"
      alt="hero"
      backgroundColor="transparent"
    />
  );
};

const Location = () => {
  return (
    <div className={classNames('w-full overflow-hidden p-4 flex flex-col items-center justify-center text-black')}>
      <div className={classNames('relative w-auto h-auto overflow-hidden ')}>
        <StaticImage
          src="../resources/location.png"
          className="w-full h-auto"
          objectFit="cover"
          objectPosition="center"
          placeholder="none"
          alt="hero"
          backgroundColor="transparent"
        />
      </div>
      <div className="text-gray-800 my-5 flex flex-nowrap items-center justify-center w-full gap-4">
        <span className="flex items-end text-[14px] font-bold whitespace-pre-wrap" style={{ wordBreak: 'keep-all' }}>
          경기도 성남시 수정구 헌릉로 999 창곡동 596
        </span>
        <button
          className="text-sm whitespace-nowrap bg-gray-400 py-2 px-3 rounded text-white bg-opacity-60 hover:bg-opacity-90 cursor-pointer"
          style={{ wordBreak: 'keep-all' }}
          onClick={() => {
            window.navigator?.clipboard?.writeText?.('경기도 성남시 수정구 헌릉로 999 창곡동 596').then(() => {
              window.alert('주소가 클립보드에 복사되었습니다.');
            });
          }}
        >
          주소 복사
        </button>
      </div>
    </div>
  );
};

const Section = ({ className = '', children }) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full m-auto text-white max-w-screen-sm mx-auto',
        className
      )}
    >
      {children}
    </div>
  );
};

export default function FacilityPage(props) {
  return (
    <Layout className="bg-white relative">
      <SEO title={null} titleTemplate="" />
      <Header color noHeight className="sticky top-0 left-0 right-0 z-10 flex" />
      <Section className="pt-16">
        <Location />
      </Section>
      <Section className="">
        <a href="http://naver.me/GttYJixf" target="_blank">
          <LocationMapImage />
        </a>
      </Section>
      <Footer />
    </Layout>
  );
}
